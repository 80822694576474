import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Footer from './Footer';
import { GalleryGroup } from './Gallery';
import Gallery from './Gallery';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import { Bees } from './components/BeeAnimation';

const qs = queryString.parse(window.location.search);

export interface Gallery {
  galleryId: number;
  name: string;
  items: GalleryItem[];
}

export interface GalleryItem {
  itemId: number;
  title: string;
  image: string;
  thumb: string;
}

interface FormData {
  beeName: string;
  name: string;
  uuid: string;
  email: string;
  amount: number;
  agreement: boolean;
}

function Page({ page }: { page: number }) {
  return (
    <div>
      <header>
        <a href="/">
          <img src={require('./images/logo.png')} width={320} alt="" />
        </a>
        <video src={require('./images/bees.mp4')} muted autoPlay loop />
      </header>
      <main className="page">
        {page === 0 && (
          <video
            src={require('./images/section-1-video.mp4')}
            muted
            autoPlay
            loop
          />
        )}
        {page === 20 && !qs.uuid && (
          <div
            className="section-8-image-container"
            style={{ position: 'relative' }}
          >
            <img
              className="section-8-img"
              src={require('./images/croatia.png')}
              alt="GigaBeetna pcelica"
            />
            <Bees box={{ minX: 0, maxX: 600, minY: 0, maxY: 600 }} />
          </div>
        )}
        {YOUTUBE[page]}
        {YOUTUBE[page] == null && HEADS[page] && (
          <img src={HEADS[page]} alt="" />
        )}
        <a className="back" href="/">
          Povratak
        </a>
        <div className={page !== 18 ? 'content' : ''}>
          {PAGES[page]}
          {page !== 17 && page !== 18 && page !== 19 && page !== 20 && (
            <div className="menu">
              <h2>Edukacija</h2>
              <ul>
                <li>
                  <a href="/o-pcelama">O pčelama</a>
                </li>
                <li>
                  <a href="/zivot-u-kosnici">Život u košnici</a>
                </li>
                <li>
                  <a href="/proizvodnja-meda">Proizvodnja meda</a>
                </li>
                <li>
                  <a href="/radim-li-kao-pcela">Radim li stvarno kao pčela?</a>
                </li>
                <li>
                  <a href="/karijera-jedne-pcele">
                    Koliko traje karijera jedne pčele?
                  </a>
                </li>
                <li>
                  <a href="/gdje-pcela-nauci-raditi-med">
                    Gdje pčela nauči raditi med?
                  </a>
                </li>
                <li>
                  <a href="/muskarci-trutovi-zene-kraljice">
                    Muškarci su trutovi, a žene kraljice?
                  </a>
                </li>
                <li>
                  <a href="/sto-kada-umre-kraljica-pcela">
                    Što kad umre kraljica pčela?
                  </a>
                </li>
                <li>
                  <a href="/pcele-se-dresiraju">Pčele se dresiraju?</a>
                </li>

                <li>
                  <a href="/biljke-za-pcele">
                    Biljke za pčele - Koje biljke privlače pčele?
                  </a>
                </li>

                <li>
                  <a href="/hotel-za-pcele">
                    Hotel za pčele - Zašto i kako ga izraditi?
                  </a>
                </li>

                <li>
                  <a href="/med-probiotik">
                    Jeste li znali da je med odličan probiotik?
                  </a>
                </li>

                <li>
                  <a href="/smanjenje-stope-oprasivanja">
                    Jeste li znali da zagađenje zraka smanjuje stopu oprašivanja
                    za čak 31 posto?
                  </a>
                </li>

                <li>
                  <a href="/pet-mocnih-recepata">Pet moćnih mednih recepata</a>
                </li>

                <li>
                  <a href="/kako-zuje-pcele-u-hrvatskoj">
                    Kako zuje pčele u Hrvatskoj?
                  </a>
                </li>

                <li>
                  <a href="/najkorisnije-cudoviste">
                    Najkorisnije „čudovište“ na svijetu ima pet očiju!
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
}

let YOUTUBE = [
  null,
  <iframe
    width="800"
    height="450"
    src="https://www.youtube.com/embed/yg1m6pNGAnw?controls=0"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>,
  <iframe
    width="800"
    height="450"
    src="https://www.youtube.com/embed/XV5i2dgVxmg?controls=0"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>,
  <iframe
    width="800"
    height="450"
    src="https://www.youtube.com/embed/W9vcgzCHc-0?controls=0"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>,
  null,
  null,
  null,
  null,
  null,
  null,
  <iframe
    width="800"
    height="450"
    src="https://www.youtube.com/embed/A6NIMIYZXMc?controls=0"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>,
  <iframe
    width="800"
    height="450"
    src="https://www.youtube.com/embed/dG-TPjz9beI?controls=0"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>,
  null,
  null,
  null,
  null,
  null,
  <iframe
    width="800"
    height="450"
    src="https://www.youtube.com/embed/k4oT6RzsBrw?controls=0"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>,
];

let PAGES = [
  <Page0 />,
  <Page1 />,
  <Page2 />,
  <Page3 />,
  <Page4 />,
  <Page5 />,
  <Page6 />,
  <Page7 />,
  <Page8 />,
  <Page9 />,
  <Page10 />,
  <Page11 />,
  <Page12 />,
  <Page13 />,
  <Page14 />,
  <Page15 />,
  <Page16 />,
  <Page17 />,
  <Page18 />,
  <Page19 />,
  <Page20 />,
];

let HEADS = [
  null,
  require('./images/edu/t1.jpg'),
  require('./images/edu/t2.jpg'),
  require('./images/edu/t3.jpg'),
  require('./images/edu/t4.jpg'),
  require('./images/edu/t5.jpg'),
  require('./images/edu/t6.jpg'),
  require('./images/edu/t7.jpg'),
  require('./images/edu/t8.jpg'),
  require('./images/edu/t9.jpg'),
  require('./images/edu/t10.jpg'),
  require('./images/edu/t11.jpg'),
  require('./images/edu/t12.jpg'),
  require('./images/edu/t13.jpg'),
  require('./images/edu/t14.jpg'),
  require('./images/edu/t15.jpg'),
  require('./images/edu/t16.jpg'),
  require('./images/edu/t17.jpg'),
];

function Page0() {
  return (
    <div>
      <h1>GigaBEEtan projekt za GigaBEEtne pčele</h1>
      <p>
        Mali koraci vode do velikih stvari.{' '}
        <b>
          Upravo zato činimo pravu stvar i štitimo pčele jer one su zaista –
          gigaBEEtne.
        </b>
      </p>
      <p>
        Za sve one koji misle da su ljudi najbitnija živa bića, brojke govore
        drugačije. Od cjelokupne populacije na našoj planeti, ljudska vrsta čini
        samo 0,1 %. Stoga je od životne važnosti osvijestiti utjecaj malih živih
        bića na postojanje života na Zemlji. Jedno od tih bića su upravo –{' '}
        <b>pčele</b>.
      </p>
      <p>
        Svojom su marljivošću nedvojbeno jedna od najvažnijih vrsta na svijetu,
        a njihov glavni zadatak je oprašivanje biljaka. Kako bismo ih zaštitili,
        odlučili smo im izgraditi stabilne i zaštićene nastambe kako bi im
        osigurali stabilan i siguran dom.
      </p>
      <h2>Tko će i kako sve to napraviti?</h2>
      <p>
        Po prvi puta u ovom dijelu svijeta, uz pomoć stručnih pčelara,{' '}
        <a href="https://www.a1.hr/" target="_blank" rel="noreferrer">
          A1&nbsp;Hrvatska
        </a>{' '}
        i{' '}
        <a href="https://www.go2digital.hr/" target="_blank" rel="noreferrer">
          Go2Digital
        </a>{' '}
        osmislili su projekt i platformu za spas pčela. Kako bismo potaknuli sve
        na spas pčela, izgradili smo dva hotela za pčele. Jedan se nalazi kod
        sjedišta A1 Hrvatska na Vrtnom putu, dok je drugi pozicioniran uz
        Go2Digital digitalne ekrane na HALA Zagreb.
      </p>
      <img
        style={{ maxWidth: '100%' }}
        src={require('./images/hotel.jpg')}
        alt="Hotel za pčele Hala"
      />
      <img
        style={{ maxWidth: '100%' }}
        src={require('./images/o-projektu-hala.jpg')}
        alt="Hotel za pčele Hala"
      />
      <p>
        A1 Hrvatska kroz program{' '}
        <a href="https://www.a1.hr/tko-smo-mi/drustvena-odgovornost/povezani-s-prirodom">
          Čini pravu stvar
        </a>{' '}
        promiče ekološki i društveno prihvatljivije, učinkovitije i održivije
        načine rada i života. Strategiju poslovanja kompanija temelji na
        ciljevima održivog razvoja Ujedinjenih naroda s naglaskom na tri cilja -
        smanjenju utjecaja na okoliš i klimatske promjene, unaprjeđenju
        digitalnih vještina i stvaranju sigurnijeg online okruženja te poticanju
        društvene raznolikosti i rodne ravnopravnosti u poslovnom okruženju.
      </p>
    </div>
  );
}

function Page1() {
  return (
    <div>
      <h1>O pčelama - Kratak uvod u svijet pčela</h1>
      <p>
        Medonosne pčele mali su postotak od poznatih 20 000 vrsta pčela, ali su
        najpoznatije široj javnosti radi proizvodnje meda i njihove uloge u
        polinaciji komercijalnih uroda.
      </p>
      <p>
        Uz medonosne pčele postoje i divlje solitarne pčele. One žive u
        košnicama, ne roje se, nemaju maticu, niti radilice te sve rade same.
      </p>
      <p>
        Jedna solitarna pčela oprašuje jednako efikasno kao i 120 medonosnih
        pčela.
      </p>
      <ul className="withimgs">
        <li>
          <img src={require('./images/pages/t1-1.png')} alt="" />
          Na svijetu postoji <b>94 milijuna košnica.</b>
        </li>
        <li>
          <img src={require('./images/pages/t1-2.png')} alt="" />U jednoj
          košnici nalazi se <b>50 000 pčela.</b>
        </li>
        <li>
          <img src={require('./images/pages/t1-3.png')} alt="" />
          Pčela prosječno proizvede <b>1/3 čajne žličice meda u svom životu.</b>
        </li>
        <li>
          <img src={require('./images/pages/t1-4.png')} alt="" />
          Košnica pčela može proizvesti oko <b>27 kg meda po sezoni.</b>
        </li>
        <li>
          <img src={require('./images/pages/t1-5.png')} alt="" />
          Pčele međusobno komuniciraju uz pomoć feromona.
        </li>
      </ul>
    </div>
  );
}

function Page2() {
  return (
    <div>
      <h1>Život u košnici - Vrste pčela u pčelinjoj zajednici</h1>
      <p>
        U normalnoj pčelinjoj zajednici medonosnih pčela živi i radi tri vrste
        pčela koje razlikujemo prema spolu, izgledu, obliku, veličini i radnim
        zadacima. To su matica, trut i radilica.
      </p>
      <ul
        className="withimgs"
        style={{ marginLeft: '-20px', marginRight: '-20px', textAlign: 'left' }}
      >
        <li>
          <img src={require('./images/pages/t2-1.png')} alt="" />
          <strong>Matica</strong>
          - 1 po koloniji pčela
          <br />
          - Pari se s 12 - 18 trutova
          <br />- Može izleći do 12 000 jaja u danu
        </li>

        <li>
          <img src={require('./images/pages/t2-2.png')} alt="" />
          <strong>Trut</strong>
          - Jedini zadatak je parenje
          <br />- Umire nakon parenja
        </li>

        <li>
          <img src={require('./images/pages/t2-3.png')} alt="" />
          <strong>Radilica</strong>
          - Brine o matici i jajima
          <br />
          - Traži i obrađuje nektar za hranu
          <br />
          - Gradi saće, čisti i čuva košnicu
          <br />- Hrani ličinke
        </li>
      </ul>
    </div>
  );
}

function Page3() {
  return (
    <div>
      <h1>Proizvodnja meda - Proces nastanka meda u košnici</h1>
      <p>
        Proizvodnja meda zahtjeva mnogo vremena i energije. Mnoštvo je zadataka
        delegirano između pčela radilica i čitave košnice kako bi se proizveo
        med.
      </p>
      <p>
        Proces izrade meda unutar košnice možemo sažeti na četiri koraka:
        prikupljanje, obrađivanje, dehidraciju i pohranu.
      </p>
      <ul className="withimgs half">
        <li>
          <img src={require('./images/pages/t3-1.png')} alt="" />
          <strong>1. Prikupljanje</strong>
          <br />
          Pčele sišu nektar s cvijeća uz pomoć proboscisa i spremaju ga u svoj
          pčelinji želudac.
        </li>
        <li>
          <img src={require('./images/pages/t3-2.png')} alt="" />
          <strong>2. Obrađivanje</strong>
          <br />
          Unutar košnice pčele prosljeđuju nektar na druge pčele koje ga
          nekoliko puta obrađuju i vraćaju.
        </li>
        <li>
          <img src={require('./images/pages/t3-3.png')} alt="" />
          <strong>3. Dehidracija</strong>
          <br />
          Pčele pohranjuju nektar u stanicama i suše ga svojim krilima.
        </li>
        <li>
          <img src={require('./images/pages/t3-4.png')} alt="" />
          <strong>4. Pohrana</strong>
          <br />
          Pčele zatvaraju voskom stanice napunjene medom. Pri udjelu od 18% vode
          med je nekvarljiv.
        </li>
      </ul>
    </div>
  );
}

function Page4() {
  return (
    <div>
      <h1>Radim li stvarno kao pčela?</h1>
      <p>
        Za svog kratkog života najuspješnije pčele proizvedu jednu žličicu meda.
        Ipak, prosječna pčela daje znatno manje meda, 1/3 čajne žličice. Za to
        pčela 12 puta u danu izađe iz košnice i obiđe oko 2.500 cvjetova.
        Inteligentno je biće koje pamti i uči. Jedini je insekt koji proizvodi
        hranu za čovjeka i presudno je važna za opstanak naše planete. Ta mala
        zujalica može se od svog doma udaljiti i do 8 kilometara, a put nazad će
        pronaći bez problema. Želite li nekoga pohvaliti za ustrajan, koristan,
        uporan i redovit rad, „radiš kao pčelica“ bit će mu najbolja pohvala.
      </p>
    </div>
  );
}

function Page5() {
  return (
    <div>
      <h1>Koliko traje karijera jedne pčele?</h1>
      <p>
        Ovdje dobro pristaje naslov knjige i filma „Plesala je jedno ljeto“. Dok
        ljetne pčele radilice koje puno rade i puno se troše žive kraće, u
        prosjeku oko 6 tjedana, zimske jedinke više miruju i zadržavaju se u
        košnici pa i žive duže, oko 4 do 5 mjeseci.{' '}
      </p>
    </div>
  );
}

function Page6() {
  return (
    <div>
      <h1>Gdje pčela nauči raditi med?</h1>
      <p>
        Pčele uče od starijih i iskusnijih. Na početku karijere rade jednostavne
        poslove – prvo čiste svoju rodnu ćeliju, pa potom i sve druge. Prvo
        napredovanje znači da će hraniti starije larve, a stepenica više je
        hranjenje mlađih larvi. Sljedeći napredakje pozicija u proizvodnji
        voska, pravljenje mednih lončića, nošenje hrane i obavljanje pogrebnih
        aktivnosti. Kada dođu do sredine svog života, pčele stare dvadesetak
        dana stražarit će na ulazu u košnicu i štititi ju od neprijatelja, te
        time zaslužiti obavljanje najčasnije dužnosti do kraja svog života, a to
        je prikupljanje nektra, peludi, vode oprašivanje.{' '}
      </p>
    </div>
  );
}

function Page7() {
  return (
    <div>
      <h1>Muškarci su trutovi, a žene kraljice?</h1>
      <p>
        Ah ta generaliziranja... Ne ide to baš tako. U pčelinjem svijetu nema
        zabušavanja niti mjesta lijenosti. Rade svi i svatko pridonosi na svoj
        način.
      </p>
      <p>
        Trutovi nemaju oca. Nastaju od neoplođenog jajašca pa imaju samo majku,
        djeda i baku. Osim toga, imaju duplo manji broj kromosoma od matice ili
        pčele radilice. Ali, bez kvalitetnog truta, nema ni kvalitetne košnice.
        Trutovi svojim feromonima potiču pčelinju zajednicu na ukupnu aktivnost
        – gradnju saća, veći unos nektara, veće zalihe peludi i meda i sigurniju
        obranu od neprijatelja.
      </p>
      <p>
        Matica, kraljica pčela, jedina je prava žena u košnici, a u stanju je
        dnevno položiti količinu jajašaca veću i od sebe same. Dok se uzgaja,
        nova matica hrani se isključivo matičnom mliječi. Tako bogata prehrana
        čini maticu dvostruko većom od pčele radilice. U jednoj sezoni matica
        položi do 200.000 jajašaca, ovisno o snazi pčelinje zajednice, koja pak
        ovisi o aktivnostima pčela radilica i organizaciji trutova. Jednostavno
        rečeno – u košnicama svi su jednako BEEtni.
      </p>
    </div>
  );
}

function Page8() {
  return (
    <div>
      <h1>Što kad umre kraljica pčela?</h1>
      <p>
        Kad umre kraljica-matica, naravno, nastane kaos. No, samo privremeno jer
        novu maticu nađu u samo 30 dana. Toliko je, naime, potrebno za sljedeći
        proces - pčele radilice pronađu najkvalitetnija jaja ili larve mlađe od
        3 dana, potom ih smjeste u okomite, posebno izgrađene „kraljičine
        ćelije“ i tamo hrane matičnom mliječi. Kad stasaju i postanu plodne, ove
        jedinke izlaze iz košnice u svadbene letove i druženje s mužjacima, a
        pri tome se i međusobno bore i pokušavaju ubiti ostale matice-djevice.
        Pobjednica se vraća u košnicu, postaje nova kraljica-matica i počne svoj
        životni ciklus polaganja jajašaca.
      </p>
    </div>
  );
}

function Page9() {
  return (
    <div>
      <h1>Pčele se dresiraju?</h1>
      <p>
        Da, čovjek je posegnuo i za dresurom pčela. Pčele vole raznolikost i
        koriste velik broj dostupnih biljaka. Ipak, ponekad da bi se ostvarilo
        sigurno oplođivanje cvjetova biljaka u uzgoju treba stimulirati posjet
        pčela određenim biljnim vrstama. Metode su jednostavne: dresura
        aromatiziranim sirupom, metoda špricanja i metoda spremanja peludnog
        praha preko kutije (inserta). Ali, možda ćete se složiti da bi ponekad
        bilo bolje da pčele dresiraju nas jer kod pčela je rad rješenje za sve.
        U košnicama vlada besprijekoran red, mir i čistoća. Organizirane su i
        svatko zna svoja zaduženja i rokove, a najvažnije, zna se tko određuje
        zaduženja, a tko ih izvršava. I jedni i drugi moraju biti podjednako
        savjesni i raditi za dobrobit cjelokupne zajednice u košnici. Zvuči
        nedostižno? Ne za pčele...
      </p>
    </div>
  );
}

function Page10() {
  return (
    <div>
      <h1>Biljke za pčele - Koje biljke privlače pčele?</h1>
      <p>
        Postoji veliki izbor divljih i kultiviranih biljaka koje privlače pčele
        i ostale oprašivače te im osiguravaju vrijedan nektar i pelud tijekom
        cijele godine. Izdvojili smo nekoliko lako dostupnih i jednostavnih za
        uzgoj.
      </p>
      <ul
        className="withimgs biljke"
        style={{
          marginLeft: '-20px',
          marginRight: '-20px',
          textAlign: 'center',
        }}
      >
        <li>
          <img src={require('./images/biljke/Lavanda.png')} alt="" />
          <strong>Lavanda</strong>
        </li>

        <li>
          <img src={require('./images/biljke/Ruzmarin.png')} alt="" />
          <strong>Ružmarin</strong>
        </li>

        <li>
          <img src={require('./images/biljke/Kadulja.png')} alt="" />
          <strong>Kadulja</strong>
        </li>

        <li>
          <img src={require('./images/biljke/Metvica.png')} alt="" />
          <strong>Metvica</strong>
        </li>

        <li>
          <img src={require('./images/biljke/Persin.png')} alt="" />
          <strong>Peršin</strong>
        </li>

        <li>
          <img src={require('./images/biljke/Timijan.png')} alt="" />
          <strong>Timijan</strong>
        </li>

        <li>
          <img src={require('./images/biljke/Mazuran.png')} alt="" />
          <strong>Mažuran</strong>
        </li>

        <li>
          <img src={require('./images/biljke/Neven.png')} alt="" />
          <strong>Neven</strong>
        </li>

        <li>
          <img src={require('./images/biljke/Kopar.png')} alt="" />
          <strong>Kopar</strong>
        </li>
      </ul>
    </div>
  );
}

function Page11() {
  return (
    <div>
      <h1>Hotel za pčele - Zašto i kako ga izraditi?</h1>
      <p>
        Mnoge vrste divljih solitarnih pčela doživjele su dramatičan pad u
        populaciji. Možemo im pomoći i potaknuti ih da se gnijezde lokalno,
        izgradnjom hotela za pčele.
      </p>
      <p>Hotel za pčele sastoji se od:</p>
      <ul>
        <li>Okvira / drvene kutije od bora, smreke ili hrasta</li>
        <li>Blokova za gniježđenje s tunelima različitih promjera i dubina</li>
        <li>Bambusa ili trske promjera 1cm i manje</li>
        <li>Krova kako bi zaštitili blokove za gniježđenje</li>
      </ul>
      <p>
        Kako biste potaknuli gnijezda različitih vrsta pčela, drveni blokovi
        trebaju biti duljine 15cm, s tunelima različitih promjera rupa izbušenim
        u njima. Mogu se također koristiti bambus ili trska, promjera 1cm ili
        manje. Blokove za gniježđenje i cjevasti biljni materijal treba staviti
        i čvrsto upakirati u okvir pčelinjeg hotela.
      </p>
      <h2>Napomene</h2>
      <ul>
        <li>
          Nemojte koristiti lakirano ili drvo tretirano pritiskom jer kemikalije
          unesene u drvo mogu biti štetne za pčele koje se gnijezde.
        </li>
        <li>
          Nemojte koristiti ljepilo za pričvršćivanje materijala za gniježđenje
          na okvir jer se ti materijali moraju mijenjati svake godine.
        </li>
      </ul>

      <p>
        <a
          className="btn download"
          style={{
            color: 'white',
            margin: '30px auto 30px auto',
            fontSize: '1em',
            height: '60px',
            maxWidth: '340px',
            paddingRight: '80px',
          }}
          download
          href="/Kako_izraditi_hotel_za_pcele.pdf"
        >
          Preuzmi upute za izradu hotela za pčele
        </a>
      </p>
    </div>
  );
}

function Page12() {
  return (
    <div>
      <h1>Jeste li znali da je med odličan probiotik?</h1>

      <p>
        Kako vi volite svoj med? Namazan na tost, s maslacem? U čaju? Koristite
        li ga kao hranu ili kao lijek?
      </p>

      <p>
        Koja god varijanta bila u pitanju, med je dragocjena, potpuno prirodna i
        ljekovita namirnica koja dolazi direktno iz prirode. Sastavljen je od
        složene kombinacije ugljikohidrata (75-80%), vode (13 – 18%) te od
        sitne, ali moćne količine dragocjenih sastojaka kao što su organske
        kiseline, aminokiseline, proteini, enzimi, minerali, vitamini i fenolne
        kiseline. Tu su i vitamini B skupine (B2, B3, B5, B6), vitamin C i folna
        kiselina, te minerali kalij, kalcij, natrij, fosfor, magnezij, željezo,
        cink, mangan, bakar i selen.
      </p>

      <p>Kada je pravi trenutak za posegnuti za teglicom meda:</p>
      <ol className="">
        <li>
          <strong>Med je odličan probiotik.</strong>
          Regulira šećer u krvi, pomaže kod želučanih tegoba. Ako vas od meda
          ipak zaboli želudac, moguće je da ste konzumirali lažnjak, ili pak
          reagirate na prezasićenu otopinu monosaharida jer med povuče dio vlage
          iz sluznice želuca što izaziva mučninu ili bolove koji u pravilu
          prolaze već kroz par minuta.
        </li>

        <li>
          <strong>Riječ je o najsavršenijem proizvodu prirode.</strong>
          Doslovno je savršen jer na svijetu ne postoji pčela koja bi napravila
          loš med. Njegova loša kvaliteta može biti jedino rezultat lošeg i
          nestručnog pčelara ili industrijske obrade.
        </li>

        <li>
          <strong>Med ima i ljekovita svojstva:</strong>
          antibakterijsko, antifungalno, antiseptičko. Pomaže kod čireva,
          opeklina i zacjeljivanja rana; učinkovitiji je od svih terapija koje
          se primjenjuju nakon kirurških operacija, pa rane tretirane medom
          zacjeljuju 4 – 5 puta brže. Antibakterijska svojstva meda toliko su
          snažna da se ne može pokvariti – jestivi med star preko 3.000 godina
          pronađen je i u Tutankamonovoj grobnici. Maje su ga smatrale hranom
          bogova, a Grci i Rimljani bez meda nisu ni ratovali – vojnici su ga
          koristili za brže zacjeljivanje rana.
        </li>

        <li>
          <strong>Također terapeutski djeluj i na naš dišni sustav:</strong>
          umiruje kašalj, osnažuje imunitet iscrpljen prehladama, pomaže kod
          sezonskih alergija.
        </li>
      </ol>

      <p>
        Med je, jednostavno rečeno: čudo prirode! Izuzetne je hranjivosti,
        sadrži u sebi sve što izgrađuje ljudski organizam i ne može se nikad
        pokvariti.
      </p>
    </div>
  );
}

function Page13() {
  return (
    <div>
      <h1>
        Jeste li znali da zagađenje zraka smanjuje stopu oprašivanja za čak 31
        posto?
      </h1>

      <p>
        Kako pčele znaju gdje je najslasnija hrana? Pa, kao i čovjek, po mirisu!
        A što kad zagađenje zraka prikrije zamamni miris cvijeća? Onda su pčele,
        leptiri, moljci i ostali oprašivači zbunjeni i onesposobljeni tražiti
        ispašu koja je tako dragocjena – i kukcima, i ljudima. Osim što
        zagađenja zraka koja svakodnevno udišemo štete našem zdravlju, ona
        uništavaju i prirodne ekosustave o kojima ovisi život na Zemlji. Nedavna
        istraživanja otkrila su da zagađenje zraka smanjuje stopu oprašivanja za
        čak 31 posto. U ekstremnim slučajevima zagađenja, prisutnost dušikovog
        oksida i ozona u zraku smanjio je broj posjeta oprašivača za čak 90
        posto. Zagađenje zraka nastaje izgaranjem raznih goriva. Otpadni
        proizvodi i nečistoće reagiraju u atmosferi i proizvode štetne tvari
        poput dušikovih oksida, ozona ili sumpornih oksida. Prema procjenama
        Svjetske zdravstvene organizacije, oni su uzrok oko 4 milijuna smrtnih
        slučajeva godišnje, a možemo se samo nadati da Hrvati ne spadaju u čak
        91% svjetske populacije koja živi u područjima gdje onečišćenje zraka
        prelazi preporučene granice.
      </p>

      <p>
        Kako utječe na ljude, zagađenje zraka utječe i na prirodu. Biljkama je
        zbog ozona i atmosferskih čestica ugrožena sposobnost fotosinteze.
        Insekti pak, iako biljke za oprašivanje nalaze i na druge načine, pate
        od dezorijentacije i nemogućnosti da pronađu prepoznatljive mirise koji
        njima, a i nama život znače. Pčele znaju gdje je zrak čist...
        <br />
        ...ali bilo bi ih bolje preduhitriti. Umjesto da prisutnost pčela
        koristimo za detekciju onečišćenja, pametnije je, bolje i korisnije
        sustavno u gradove i okoliš uvoditi rješenja za mjerenje kvalitete tla i
        zraka.
      </p>

      <p>
        Projekt <a href="/">GigaBEEtno</a> uključuje instalaciju upravo takvih
        pametnih IoT (eng. Internet of things) rješenja i uređaja. Partner
        projekta, A1 Hrvatska,kao predvodnik u ICT segmentu prepoznaje
        vrijednost brige za zajednicu i okoliš te u tom smislu postavlja najviše
        standarde. „Svoju ponudu i tehnološke dosege prilagođavamo potrebama
        tržišta i društva, a naša uloga u projektu GigaBEEtno upravo je pokazati
        predanost takvim životno važnim temama. Korištenjem{' '}
        <a
          href="https://www.pametnarjesenja.hr/"
          target="_blank"
          rel="noreferrer"
        >
          suvremenih informacijsko-komunikacijskih tehnologija
        </a>{' '}
        moći ćemo dobiti uvid u kvalitetu zraka i detektirati problem ukoliko
        postoji te tako potaknuti akcije za ublažavanje štetnih učinaka
        onečišćenja“, poručuje Renata Štefić, direktorica marketinga za poslovne
        korisnike A1 Hrvatska.
      </p>
    </div>
  );
}

function Page14() {
  return (
    <div>
      <h1>Pet moćnih mednih recepata</h1>

      <p>
        Kao što svako misto svoju feštu ima, tako i svaka nacija na svijetu ima
        svoje recepture vezane uz hranjivost i ljekovitost pčelinjih proizvoda,
        posebice meda. Ovo su naših pet favorita!
      </p>

      <ol className="five-rec">
        <li>
          <strong>Med i hren</strong>
          <br />U pola kilograma meda umiješati 10 žlica naribanog svježeg,
          domaćeg hrena. Pohraniti u staklenku, držati u hladnjaku i svako jutro
          uzeti jednu žličicu ovog pripravka koji pospješuje rad svih žlijezda s
          unutarnjim izlučivanjem i razmjenu tvari, a super je za pročišćavanje
          organizma. Ublažava i tegobe od reume i gihta, pospješuje rad bubrega
          i mokraćnih kanala, koristan je za želučano-crijevni sustav, jetru i
          gušteraču.
        </li>

        <li>
          <strong>Med, đumbir i kurkuma</strong>
          <br />
          Ovo je pravi booster imuniteta! Treba vam 200 grama meda, četiri čajne
          žličice naribanog, svježeg đumbira, dvije čajne žličice kurkume.
          Možete dodati i sok jednog limuna. Sve pomiješajte i staklenku držite
          na hladnom. Možete konzumirati žlicom, ujutro natašte, ili umiješati
          žlicu pripravka u topli (ne vrući!) čaj.
        </li>

        <li>
          <strong>Med i zeleni orasi</strong>
          <br />
          Zelene orahe mnogi koriste kao eliksir za štitnjaču. Pomiješani s
          medom, dobri su i za jetru, želudac i krv te kod liječenja bronhitisa.
          40 zelenih oraha prerežite na četvrtine, poslažite u teglu i prelijte
          kilogramom meda. Ostavite na suncu 40 dana i eliksir je gotov.
          Pospremite na tamno i zaštićeno mjesto, uzimajte jednu žličicu ujutro
          na prazan želudac.
        </li>

        <li>
          <strong>Med i sjeme koprive</strong>
          <br />
          Kopriva je, baš kao i med, bogata vitaminima B, C, D, E, K, te
          kalijem, željezom, magnezijem, cinkom, manganom i bakrom. Zbog toga su
          zajedno idealni za liječenje anemije. Treba vam pola kilograma
          domaćeg, prirodnog livadskog meda u koji ćete umiješati samljevenih
          100 grama sjemenki koprive i 300 grama oraha pa dodati sok od 4
          srednja limuna. Pomiješajte dobro, pospremite na suho, hladno i tamno
          mjesto i koristite redovito jednom dnevno.
        </li>

        <li>
          <strong>Med i kurkuma</strong>
          <br />
          Smjesa meda i kurkume je toliko poznata i cijenjena da ima i svoje
          posebno ime – „zlatni med“. Jednu čajnu žličicu kurkume umiješajte u
          100 grama meda. Koristite preventivno žličicu dnevno, a u kriznim
          situacijama prehlade, gripe ili iscrpljenosti po pola žličice svakih
          sat vremena pa postupno kroz par dana smanjujte dozu. Zlatni med
          možete umiješati i u šalicu toplog mlijeka prije spavanja – zaspat
          ćete kao beba.
        </li>
      </ol>
    </div>
  );
}

function Page15() {
  return (
    <div>
      <h1>Kako zuje pčele u Hrvatskoj?</h1>

      <p>
        Ova pandemija donijela je i jednu pozitivnu stvar. Zbog brojnih
        lockdownova diljem svijeta, priroda se počela obnavljati pa se tako i
        povećao broj pčela u Hrvatskoj. Prema službenim podacima, u Hrvatskoj je
        u 2021. godini zujalo čak pola milijarde pčela više nego 2020. Svi već
        znamo priču o važnosti pčela za čovječanstvo, no znamo li priču o
        Hrvatima i pčelama?
      </p>

      <p>
        Ne znamo je li do pčela ili do Hrvata, ali u našim krajevima i tradiciji
        pčele su uvijek imale poseban tretman. Od davnina davala su im se gotovo
        magična svojstva, a sve oko pčelarstva imalo je tajanstveno značenje.
        Tako su se razvila i brojna praznovjerja poput ovih:
      </p>

      <ol className="five-rec">
        <li>
          Pčelar mora dobiti jedan roj na poklon, jedan mora pronaći, a trećeg
          ukrasti. Tek tada će ga pratiti sreća i njegova će rabota biti
          uspješna.
        </li>

        <li>
          Na pčelinjak se ima postaviti životinjska lubanja, rog ili kost. To će
          pčele zaštititi od djelovanja zlih sila.
        </li>

        <li>
          Pčelareva žena ima sjesti na zemlju golim tijelom da bi rojenje bilo
          brže i djelotvornije. (ne zna se što u slučaju da pčelar nema ženu)
        </li>

        <li>
          Da se dobije med, pčele se imaju usmrtiti. Ovo se odnosi samo na uzgoj
          u tradicijskim košnicama u obliku valjka, stošca, sanduka i zvona.
          Pčele se usmrte gušenjem dimom, a med se cijedi golim rukama ili
          cjedilom.
        </li>
      </ol>

      <h2>Što je starije: med ili vosak?</h2>
      <p>
        Ako je vjerovati drevnim pisanim tragovima s područja Hrvatske, odavno
        se spominju med, vosak i voštane svijeće. Med se koristio za prehranu,
        tradicijsku medicinu, ali i za prakticiranje nekih običaja. Pravili su
        se napitci i začini za jelo, a davan je i maloj djeci u mlijeku, da im
        da energiju i snagu.
      </p>

      <h2>Dubrovačka „voskovarnica“</h2>
      <p>
        Dubrovačka republika je još u 15. stoljeću imala reguliranu proizvodnju
        voštanih svijeća. Svake je godine sklapala ugovor s majstorima i davala
        im dozvolu za proizvodnju svijeća. Određivala se cijena vosku, loju i
        svijećama, a u Dubrovniku su ih izrađivali čak i apotekari, u brojnim
        oblicima i načinima izrade koje se povezivalo s određenim običajima. Tih
        davnih stoljeća u prvom je planu bio vosak – to je bio pčelinji proizvod
        numero uno, dok je med došao u fokus tek kasnije, u 19. Stoljeću.
      </p>

      <h2>Prvi hrvatski učitelj pčelarstva</h2>
      <p>
        Kraljica Marija Terezija je 1775. godine dala objaviti „Patent o
        pčelarstvu“, postavke za širenje i razvoj pčelarstva u monarhiji.
        Ukinula je poreze i davanje na pčelarstvo i naredila da Kraljevsko
        vijeće za Hrvatsku, Slavoniju i Dalmaciju imenuje učitelja pčelarstva.
        1770. godine na tu je dužnost izabran Anton Gruber iz Varaždina.
      </p>

      <h2>Dalmatinske kamene košnice</h2>
      <p>
        Hrvatska je dugo i ustrajno njegovala tradicionalan način pčelarenja
        koji se u ovim krajevima zadržao čak do sredine 20. stoljeća. Tu su
        tradicionalne košnice i pribor, oprema i način pčelarenja. Mediteranska
        Hrvatska imala je kamene košnice: najpoznatije su one na Braču u
        samostanu Blace, a ima ih i na drugim dalmatinskim otocima, posebno na
        Hvaru.
      </p>
      <p>
        Einsteinovo upozorenje: „Kad bi pčele nestale s površine Zemlje,
        čovječanstvo bi imalo još samo četiri godine života”.
      </p>
      <p>
        Iako se broj pčela počeo povećavati, to ne znači da trebamo prestati
        brinuti za njihov opstanak. Na njih utječu razne bolesti, nametnici i
        promjene u ekosustavu. Upravo zato trebaju sve više brige i pomoć
        čovjeka.
      </p>
    </div>
  );
}

function Page16() {
  return (
    <div>
      <h1>Najkorisnije „čudovište“ na svijetu ima pet očiju!</h1>
      <p>
        Što je to: ima pet očiju, šest nogu i dva para krila? Ne, nije neko
        čudovište iz ormara ili mitskih priča. To je marljiva, svima poznata i
        draga pčelica!
      </p>
      <p>
        Zanimljiva je i darežljiva priroda. Savršeno složi sve kotačiće, da
        sustav bude održiv i funkcionira besprijekorno. Za sve koji u životu
        traže čaroliju i čuda, dovoljno je pogledati ovaj čarobni krug života:
        fascinira i oduševljava.
      </p>
      <p>
        Koliko nam je danas poznato, pčelarstvo je drevna vještina ubiranja meda
        kojom su se bavili ljudi još 15.000 godina prije Krista. Divlje branje
        meda razvilo se i unaprijedilo do proizvodnje meda u sofisticiranim
        pčelinjacima. Prvi zapis berbe meda bilježi se 2422. godine prije Krista
        i pronađen je u drevnim egipatskim grobnicama.
      </p>

      <h2>Pčele su organizirane i precizne, baš kao i sve u prirodi</h2>

      <p>
        U jednoj košnici živi oko 50.000 pčelica. Matica je samo jedna po
        koloniji pčela. Pari se sa 12 do 18 trutova, a dnevno može izleći i do
        2.000 jajašaca! Trutu je pak, jedini posao parenje, a nakon seksa umire.
        A radilice... eh, radilice! Radilice – rade: brinu o jajašcima, hrane
        larve. Čiste košnicu i uređuju je. Brinu se za maticu, brinu se za
        nektar i obrađuju ga, grade saće. Kako bi sve funkcioniralo
        besprijekorno, pčele se dogovaraju – komuniciraju plesom ili kemijski,
        feromonima. Kružnim plesom pčele skupljačice javljaju da su pronašle
        izvor hrane u blizini košnice. Tada se kreću u malom krugu, malo u
        jednu, malo u drugu stranu, par sekundi, pa i do nekoliko minuta. Kada
        skupljačice nađu nektar koji je jako udaljen od košnice, tada će ples
        biti malo složeniji, u više smjerova, pri čemu vrti zatkom ili trese
        tijelom. Reklo bi se – pčele twerkaju. Kod komuniciranja feromonima,
        važno je znati da feromon mora biti poseban, usko specifičan kako bi ga
        određena grupa prepoznala. Zbog toga ga pčele proizvode u vrlo maloj,
        limitiranoj količini kako se ne bi pretjerano iscrpljivale.
      </p>

      <h2>Smisao života jedne pčele</h2>
      <p>
        Činjenica: za jednu žličicu meda koju date svom djetetu, pojedete ju
        sami ili umiješate u čaj, desetak je pčelica radilo cijeli svoj život!
        Ili, ako vam je tako jednostavnije: jedna pčelica tijekom cijelog svog
        životnog vijeka proizvede jednu desetinu žličice meda.
      </p>

      <p>Od cvijeta do meda u 4 koraka:</p>

      <ol>
        <li>
          Pčela traži hranu: kada nađe cvijet, crpi nektar pomoću svojeg rila i
          pohranjuje ga u trbuh.
        </li>
        <li>
          Pčela probavlja: skupljačice u košnici predaju nektar drugim pčelama;
          one ga primaju kroz gutanje, obrađuju te ponovno izbacuju
        </li>
        <li>
          Pčela „suši“ med: kako bi bio vječan, medu se treba oduzeti određeni
          postotak vode. Pčele pohranjuju nektar u ćelije saća i suše ga svojim
          krilima kako bi reducirale vodu u medu.
        </li>
        <li>
          Pčela sprema med: ćelije meda pčela će zapečatiti voskom. Postotak
          vode u medu održava se na 18% i on je tada nepokvarljiv, vječan.
        </li>
      </ol>

      <p>
        Pčela je pčela? Da, ali koja, kakva? Ako ste mislili da sve pčele
        skupljaju med i da postoji samo jedna vrsta – varate se. Medne pčele
        predstavljaju samo mali udio od ukupno 20.000 vrsta poznatih pčela. One
        su samo najpopularnije, zbog toga što proizvode med ili pak imaju ulogu
        oprašivanja biljaka. U Europi obitava više od 2.000 vrsta pčela, a od
        toga ih je u Hrvatskoj oko 730 različitih vrsta.
      </p>
    </div>
  );
}

function Page17() {
  return <div></div>;
}

function Page18() {
  let [galleries, setGalleries] = useState<Gallery[] | null>(null);
  let [votes, setVotes] = useState<Map<number, number>>(new Map());
  let [hasVoted, setHasVoted] = useState<number>(
    parseInt(window.localStorage.getItem('gigabeetno-voted') || '0', 10)
  );
  let [image, setImage] = useState<string | null>(null);
  let [voteModal, setVoteModal] = useState<number | null>(null);

  useEffect(() => {
    fetch('https://www.go2digital.hr/api/gallery/group/gigabeetno/').then(
      async (resp) => {
        let data = await resp.json();
        setGalleries(data);
      }
    );

    fetch('https://www.go2digital.hr/api/gallery/vote/').then(async (resp) => {
      let data = (await resp.json()) as [number, number][];
      setVotes(new Map(data));
    });
  }, []);

  return (
    <>
      <div className="section-6-winners">
        <div className="subtitle subtitle-bold">Nagradni natječaj</div>
        <h1>Ovo je GigaBEEtna pčela</h1>
        <div>
          <img
            src={require('./images/gigaBEEtno-pobjednici.png')}
            alt="GigaBEEtno pobjednici"
          />
        </div>
        <div>
          <a className="pravila" target="_blank" href="/pravila.pdf">
            Pravila nagradnog natječaja
          </a>

          <p>
            Nagradni natječaj dječjih vrtića i osnovnih škola na temu "Ovo je
            gigaBEEtna pčela" završio je 30.10.2022.
          </p>

          <p>
            U natječaju je sudjelovalo{' '}
            <strong>87 dječjih vrtića i osnovnih škola</strong> iz cijele
            Hrvatske sa prijavljenih <strong>427 likovnih radova.</strong>
          </p>

          <p>
            <strong>Ovaj natječaj je završio.</strong>
          </p>
        </div>
      </div>

      <div className="section-6-wrap">
        {image && (
          <div className="modal" onClick={() => setImage(null)}>
            <img src={image} alt="" />
          </div>
        )}
        {galleries &&
          galleries.map((gallery) => (
            <GalleryGroup
              key={gallery.galleryId}
              votes={votes.get(gallery.items[0].itemId) || 0}
              hasVoted={[hasVoted]}
              gallery={gallery}
              openImage={setImage}
              voteModal={setVoteModal}
            />
          ))}
      </div>
    </>
  );
}

function Page19() {
  let [galleries, setGalleries] = useState<Gallery[] | null>(null);
  let [votes, setVotes] = useState<Map<number, number>>(new Map());
  let [hasVoted, setHasVoted] = useState<number>(
    parseInt(window.localStorage.getItem('gigabeetno-voted') || '0', 10)
  );
  let [image, setImage] = useState<string | null>(null);
  let [voteModal, setVoteModal] = useState<number | null>(null);

  useEffect(() => {
    fetch('https://www.go2digital.hr/api/gallery/group/gigabeetno2/').then(
      async (resp) => {
        let data = await resp.json();
        setGalleries(data);
      }
    );

    fetch('https://www.go2digital.hr/api/gallery/vote/').then(async (resp) => {
      let data = (await resp.json()) as [number, number][];
      setVotes(new Map(data));
    });
  }, []);

  return (
    <div className="section-7-page">
      <div className="subtitle subtitle-bold">Nagradni natječaj</div>
      <div>
        <h1>GigaBEEtni Bonton</h1>
        <div className="section-7-page-description">
          <p className="section-7-page-paragraph">
            S drugom sezonom projekta posvećenog našim GigaBEEtnim pčelicama
            pripremili smo i novi nagradni natječaj za polaznike osnovnih škola
            i vrtića. Od svih zainteresiranih tražimo da pokažu svoju
            kreativnost u grupnom likovnom radu i izrade GigaBEEtni Bonton.
            Riječ je o bontonima koji pravila lijepog ponašanja prikazuju kroz
            ilustracije naših najdražih malih susjeda - pčelica!
          </p>
          <div className="section-7-page-paragraph-container">
            <p>
              Pristigle radove možete vidjeti niže, a idući korak je na vama -
              glasajte za rad koji vam se najviše svidio. Tri rada s najviše
              glasova publike nagradit ćemo vrijednim nagradama.{' '}
              <strong>
                Produžujemo prijave radova zaključno do 11.10.2023. godine do
                23:59. Dana 13.10.2023. godine započet će glasovanje za najbolje
                grupne likovne radove koje traje do 25.10.2023. godine do 23:59.
                Pobjednike objavljujemo 27.10.2023.
              </strong>
            </p>
            <a className="pravila" target="_blank" href="/pravila2023.pdf">
              Pravila nagradnog natječaja
            </a>
          </div>
        </div>
        {galleries &&
          galleries.map((gallery) => (
            <GalleryGroup
              key={gallery.galleryId}
              votes={votes.get(gallery.items[0].itemId) || 0}
              hasVoted={[hasVoted]}
              gallery={gallery}
              openImage={setImage}
              voteModal={setVoteModal}
            />
          ))}
      </div>
    </div>
  );
}

function Page20() {
  const [formData, setFormData] = useState<FormData>({
    beeName: '',
    name: '',
    email: '',
    uuid: uuidv4(),
    amount: 0,
    agreement: false,
  });

  const handleSubmit = (e: FormEvent) => {
    // Access form data from the state object
    let xhr = new XMLHttpRequest(); // new HttpRequest instance
    xhr.open('POST', 'https://www.go2digital.hr/api/apps/gigabeetno_bee/');
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xhr.send(
      JSON.stringify({
        uuid: formData.uuid,
        bee_name: formData.beeName,
        name: formData.name,
        email: formData.email,
        amount: formData.amount,
      })
    );

    //console.log('Form Data:', formData);
    //e.preventDefault(); // TODO remove
  };

  const handleTextInputChange =
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setFormData({
        ...formData,
        [name]: value,
      });
    };

  const handleCheckboxChange =
    (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      setFormData({
        ...formData,
        [name]: checked,
      });
    };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let numericValue = parseFloat(value);

    setFormData({
      ...formData,
      [name]: numericValue,
    });
  };

  const handleButtonAmountClick = (amount: number) => (e: any) => {
    e.preventDefault();

    if (formData.amount === amount) {
      // If the button is already selected, deselect it
      setFormData({
        ...formData,
        amount: 0,
      });
    } else {
      // Otherwise, select the button and set the "donacija" value
      setFormData({
        ...formData,
        amount: amount,
      });
    }
  };

  let isValid =
    formData.beeName !== '' &&
    formData.name !== '' &&
    formData.email !== '' &&
    formData.amount !== 0 &&
    formData.agreement;

  return (
    <div className="section-8-page">
      {qs.uuid && qs.uuid.length > 0 ? (
        <div>
          <h1>Posvoji pčelicu za Pčelice</h1>
          <div className="section-8-certificate-container">
            <div className="section-8-thank-you-note-container">
              <div>
                <h1 className="section-8-thank-you-note-title">
                  Hvala ti na donaciji!
                </h1>
                <p>
                  Ne samo da pridonosiš očuvanju ekosustava pčela u Hrvatskoj,
                  već pomažeš i unaprjeđenju kvalitete života djece s
                  poteškoćama u razvoju.
                </p>
              </div>
              <a
                className="btn"
                href={`https://www.go2digital.hr/media/gigabeetno_bees/${qs.uuid}.pdf`}
                target="_blank"
                download
              >
                Preuzmi certifikat
              </a>
            </div>
            <img
              className="section-8-certificate"
              src={`https://www.go2digital.hr/media/gigabeetno_bees/${qs.uuid}.png`}
            />
          </div>
        </div>
      ) : (
        <div>
          <h1>Posvoji pčelicu za Pčelice</h1>
          <div className="section-7-page-description">
            <p className="section-7-page-paragraph">
              Pokrenuli smo novi projekt kojim želimo pomoći bitnim pčelicama u
              našem društvu pod nazivom{' '}
              <strong>Posvoji pčelicu za Pčelice</strong> Posvajanjem pčelica s
              naše karte pomažete{' '}
              <strong>
                Udruzi roditelja djece s teškoćama u razvoju 'Pčelice'
              </strong>
              , a prikupljeni iznos pomoći će im u
            </p>
            <div className="section-7-page-paragraph">
              <p>
                Brigu o vašoj posvojenoj pčelici prepustite nama jer one će naći
                dom u jednom od naših hotela za pčele.
              </p>
            </div>
          </div>
          <div className="section-8-form-container">
            {/*  action="https://8000.hrvoje-workstation.amp.hr/debug_post/" */}
            <img
              className="section-8-form-bees"
              src={require('./images/bees.png')}
            />
            <img
              className="section-8-form-bees-right"
              src={require('./images/bees.png')}
            />

            <form
              action="https://www.cinipravustvar.hr/api/v1/public/gigabeetno"
              method="POST"
              className="section-8-form"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="uuid" value={formData.uuid}></input>
              <input type="hidden" name="packageId" value="13"></input>
              <div className="section-8-row">
                <label className="section-8-label" htmlFor="ime-pcelice">
                  Ime pčelice
                </label>
                <input
                  className="section-8-input"
                  type="text"
                  id="ime-pcelice"
                  value={formData.beeName}
                  onChange={handleTextInputChange('beeName')}
                ></input>
              </div>

              <div className="section-8-row">
                <label className="section-8-label" htmlFor="ime-prezime">
                  Ime i prezime
                </label>
                <input
                  className="section-8-input"
                  type="text"
                  id="ime-prezime"
                  name="name"
                  value={formData.name}
                  onChange={handleTextInputChange('name')}
                ></input>
              </div>

              <div className="section-8-row">
                <label className="section-8-label" htmlFor="email">
                  E-mail
                </label>
                <input
                  className="section-8-input"
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleTextInputChange('email')}
                ></input>
              </div>

              <div className="section-8-row">
                <label className="section-8-label">Odaberi donaciju</label>
                <div className="section-8-suggested-amount">
                  <button
                    type="button"
                    className={`section-8-amount ${
                      formData.amount === 5 ? 'selected' : ''
                    }`}
                    onClick={handleButtonAmountClick(5)}
                  >
                    5Є
                  </button>
                  <button
                    type="button"
                    className={`section-8-amount ${
                      formData.amount === 10 ? 'selected' : ''
                    }`}
                    onClick={handleButtonAmountClick(10)}
                  >
                    10Є
                  </button>
                  <button
                    type="button"
                    className={`section-8-amount ${
                      formData.amount === 15 ? 'selected' : ''
                    }`}
                    onClick={handleButtonAmountClick(15)}
                  >
                    15Є
                  </button>
                </div>
                <input
                  className="section-8-input"
                  type="number"
                  id="donacija"
                  name="amount"
                  style={{ textAlign: 'center' }}
                  min={0}
                  value={formData.amount === 0 ? undefined : formData.amount}
                  placeholder="Slobodan unos"
                  onChange={handleNumberChange}
                ></input>
              </div>

              <div className="section-8-agreement">
                <input
                  type="checkbox"
                  id="agreement"
                  checked={formData.agreement}
                  onChange={handleCheckboxChange('agreement')}
                ></input>
                <label htmlFor="agreement">
                  Prihvaćam{' '}
                  <a href="/" target="_blank">
                    privolu o korištenju osobnih podataka
                  </a>
                </label>
              </div>

              <input
                className="section-8-submit-btn"
                type="submit"
                value="Doniraj"
                disabled={!isValid}
              ></input>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default Page;
