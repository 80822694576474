import React from 'react';

interface Box {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

interface Props {
  name: string;
  box: Box;
}

export class BeeAnimation extends React.Component<Props> {
  rootEl: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.rootEl = React.createRef();
  }

  shouldComponentUpdate(): boolean {
    return false;
  }

  componentDidMount(): void {
    if (this.rootEl.current != null)
      startAnimation(this.rootEl.current, this.props);
  }

  render() {
    return (
      <div
        ref={this.rootEl}
        style={{ position: 'absolute', top: 0, left: 0 }}
      ></div>
    );
  }
}

export function Bees(props: { box: Box }) {
  let [bees, setBees] = React.useState<string[]>([]);

  React.useEffect(() => {
    fetch('https://www.go2digital.hr/api/apps/gigabeetno_bee/').then(
      async (resp) => {
        let data = await resp.json();
        setBees(
          data.slice(0, 5).map(({ bee_name }: any) => bee_name as string)
        );
      }
    );
  }, []);

  return (
    <>
      {bees.map((name, i) => (
        <BeeAnimation key={i} name={name} box={props.box} />
      ))}
    </>
  );
}

type Vec = [number, number];
let add = (a: Vec, b: Vec) => [a[0] + b[0], a[1] + b[1]] as Vec;
let dot = (a: Vec, b: Vec) => a[0] * b[0] + a[1] * b[1];
let mul = (alpha: number, a: Vec) => [alpha * a[0], alpha * a[1]] as Vec;
let proj = (a: Vec, n: Vec) => mul(dot(a, n), n);
let reflect = (a: Vec, n: Vec) => add(a, mul(-2, proj(a, n)));
let angleX = (a: Vec) => Math.atan2(a[1], a[0]);
let rnd = (a: number, b: number) => a + Math.random() * (b - a);

function startAnimation(el: HTMLDivElement, props: Props) {
  console.log('Start animation in', el);
  let imageEl = document.createElement('img');
  imageEl.src = require('../images/bee.png');
  imageEl.width = 78;
  imageEl.height = 74;
  imageEl.style.zIndex = '100';
  imageEl.style.position = 'static';
  el.appendChild(imageEl);

  let nameEl = document.createElement('div');
  nameEl.innerText = props.name;
  nameEl.style.background = '#3A3A3A';
  nameEl.style.color = 'white';
  nameEl.style.position = 'relative';
  nameEl.style.left = 'calc(-50% + 50px)';
  nameEl.style.top = '-130px';
  nameEl.style.padding = '10px 20px';
  nameEl.style.borderRadius = '5px';
  nameEl.style.textAlign = 'center';
  nameEl.style.zIndex = '101';
  nameEl.style.display = 'none';
  el.appendChild(nameEl);

  // bounding box
  let { minX, maxX, minY, maxY } = props.box;
  let walls: Array<[(v: Vec) => boolean, Vec]> = [
    [(v) => v[1] < minY, [0, 1]], // top wall
    [(v) => v[0] > maxX, [-1, 0]], // right wall
    [(v) => v[1] > maxY, [0, -1]], // bottom wall
    [(v) => v[0] < minX, [1, 0]], // left wall
  ];

  let prevTime = 0;
  let loc: Vec = [rnd(minX + 30, maxX - 30), rnd(minY + 30, maxY - 30)];
  let speed = rnd(60, 90); // pixels per second
  let angle = Math.random() * Math.PI;
  let v: Vec = [speed * Math.cos(angle), speed * Math.sin(angle)];

  let stop = false;

  el.addEventListener('mouseover', () => {
    stop = true;
    nameEl.style.transform = `translate(${loc[0]}px, ${loc[1]}px)`;
    nameEl.style.display = 'block';
  });
  el.addEventListener('mouseout', () => {
    stop = false;
    nameEl.style.display = 'none';
  });

  function initAnimate(t: number) {
    prevTime = t;
    requestAnimationFrame(animate);
  }

  function animate(t: number) {
    let dt = (t - prevTime) / 1000.0;
    if (dt > 2.0 || stop) {
      // skip update when dt is large because probably window was not focused
      prevTime = t;
      requestAnimationFrame(animate);
      return;
    }

    loc = add(loc, mul(dt, v));

    walls.forEach(([pred, normal]) => {
      if (pred(loc) && dot(v, normal) < 0) {
        // location has passed the wall test and direction is INTO the wall
        v = reflect(v, normal);
        angle = angleX(v);
      }
    });

    imageEl.style.transform = `translate(${loc[0]}px, ${loc[1]}px) rotate(${
      angle + Math.PI
    }rad)`;

    prevTime = t;
    requestAnimationFrame(animate);
  }

  requestAnimationFrame(initAnimate);
}
